import BuyerSignup from "@/marketplace/container/Auth/BuyerSignup";
import SellerSignup from "@/marketplace/container/Auth/SellerSignup";
import React from "react";
import { useLocation } from "react-router-dom";

const Signup = () => {
  const { pathname } = useLocation()

  return (
    <div>
      {pathname === '/marketplace/seller' ? <SellerSignup /> : <BuyerSignup />}
    </div>
  );
};

export default Signup;
