import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface IAuthState {
  isAuthenticated: boolean;
  user: {} | null;
  token: string | null;
}

const initialState: IAuthState = {
  isAuthenticated: false,
  user: null,
  token: null,
};



export const slice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token } = action.payload;
      state.isAuthenticated = true;
      state.user = user;
      state.token = token;
      localStorage.setItem(
        "authState",
        JSON.stringify({
          isAuthenticated: true,
          user,
          token,
        })
      );
    },
    logOut: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      localStorage.removeItem("authState");
    },
    forgotPassword: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    },
  },
});

export const { setCredentials, logOut, forgotPassword } = slice.actions;



export const authorization = slice.reducer;

// export const selectIsAuthorized = (state: RootState): boolean => state.authorization.isAuthenticated;
export const selectAuthUser = (state: RootState) => state.authorization.user;
export const selectAuthToken = (state: RootState) => state.authorization.token;

