import React, { useRef, useState } from 'react';
import cn from 'classnames';

import {
    FormControl,
    MenuItem,
    FormHelperText,
    Select as MaterialSelect,
    SelectProps,
    InputLabel,
    useMediaQuery,
    useTheme,
    OutlinedInput,
} from '@material-ui/core';

import { useSingleSelectStyles } from './styles';

export interface ISelectOption {
    name: string;
    value: any;
}

export type SelectOptions = ISelectOption[];

export interface ISingleSelectProps extends SelectProps {
    menuTitle: string;
    data: SelectOptions;
    label?: string;
    helperText?: string;
    displayHelperText?: boolean;
}

export const SingleSelect: React.FC<ISingleSelectProps> = ({
    menuTitle,
    data,
    label,
    fullWidth,
    error,
    helperText,
    className,
    displayHelperText,
    disabled,
    ...other
}) => {
    const theme = useTheme();
    const isNotNative = useMediaQuery(theme.breakpoints.up('md'));

    const inputLabel = useRef<HTMLLabelElement | null>(null);
    const [labelWidth, setLabelWidth] = useState(0);

    React.useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [label]);

    const classes = useSingleSelectStyles();
    return (
        <FormControl
            className={cn(className, { [classes.fullWidth]: fullWidth })}
            error={error}
        >
            <InputLabel
                ref={inputLabel}
                shrink={Boolean(other.value) || false}
                disabled={disabled}
            >
                {label}
            </InputLabel>

            <MaterialSelect
                {...other}
                disabled={disabled}
                native={!isNotNative}
                input={
                    <OutlinedInput
                        notched
                        labelWidth={other.value ? labelWidth : 0}
                        name={other.name}
                    />
                }
            >
                {isNotNative ? (
                    <MenuItem value="" disabled>
                        {menuTitle}
                    </MenuItem>
                ) : (
                    <option disabled value="" />
                )}

                {isNotNative
                    ? data.map(({ name, value }, i) => (
                          <MenuItem
                              /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                              key={value}
                              /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                              value={value}
                              selected={Boolean(i)}
                          >
                              {name}
                          </MenuItem>
                      ))
                    : data.map(({ name, value }) => (
                          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                          <option key={value} value={value}>
                              {name}
                          </option>
                      ))}
            </MaterialSelect>

            {helperText ? (
                <FormHelperText className={classes.helperText}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
};
