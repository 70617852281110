import React from "react";
import cn from "classnames";

import { TextField as MuiTextField } from "formik-material-ui";
import { ErrorMessage, Field } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core";
import { colors } from "@/bidda/theme/colors";
import { fontSizes } from "@/bidda/theme/fonts";

export const useFieldStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      // "& :hover": {
      //   border: `1px solid ${colors.grey[300]} !important`,
      // },
      "&:focus-visible": {
        border: "none",
      },
    },
    "& p": {
      minHeight: 20,
      whiteSpace: "pre-wrap",
      margin: "2px 0px",
      fontSize: "11px",
    },
    "& input": {
      height: "11px",
      marginBottom: theme.spacing(0),
      padding: "16px 20px",
      backgroundColor: "#fff",
      // border: `1px solid ${colors.grey[300]} !important`,
      // boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      borderRadius: "8px",
      "&:hover": {
        // border: `1px solid ${colors.grey[300]} !important`,
      },
      "&::placeholder": {
        fontSize: fontSizes.small[20],
      },
      "&:focus-visible": {
        border: "none",
      },
    },
    "& fieldset": {
      border: `1px solid ${colors.grey[300]}`,
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      "&:focus-visible": {
        border: "none",
      },
    },
  },
}));

type ExtendedTextFieldProps = TextFieldProps & {
  noHelperText?: boolean;
  autoComplete?: string;
};

export const TextField: React.FC<ExtendedTextFieldProps> = ({
  noHelperText = false,
  autoComplete = "off",
  ...props
}) => {
  const classes = useFieldStyles();

  return (
    <>
      <Field
        component={MuiTextField}
        {...props}
        className={cn(classes.root, props.className)}
        helperText={
          noHelperText ? null : props.helperText ? props.helperText : " "
        }
        autoComplete={autoComplete === "off" ? "nope" : autoComplete}
      />
      {/* <ErrorMessage name="password" /> */}
    </>
  );
};
