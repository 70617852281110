export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

export const COMPANY_NOT_SET_ERROR = '[Company Error]: Sorry, we can not process this request. If this issues continues, please reach out to Bidda support.';

export const UNPROCESSED_TRANSACTION = 'Sorry, we were unable to process your payment. Please try again.';

export const AUCTION_FEE_SUCCESS = 'Your auction fee payment was successful';

export const DEFAULT_SNACKBAR_DURATION = 3000;

export const maxFileSize = 200 * 1024 * 1024;

// export const times = Array(24 * 4).fill(0).map((_, i) => { return ('0' + ~~(i / 4) + ':0' + 60  * (i / 4 % 1)).replace(/\d(\d\d)/g, '$1') });

export const times = [
  '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', 
  '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00' 
];

export const timezones = [
  {
    "value": "Etc/GMT+12",
    "abbr": "DST",
    "offset": -12,
    "isdst": false,
    "text": "UTC-12:00",
    "utc": [
      "Etc/GMT+12"
    ]
  },
  {
    "value": "Pacific/Midway",
    "abbr": "U",
    "offset": -11,
    "isdst": false,
    "text": "UTC-11:00",
    "utc": [
      "Etc/GMT+11",
      "Pacific/Midway",
      "Pacific/Niue",
      "Pacific/Pago_Pago"
    ]
  },
  {
    "value": "Pacific/Honolulu",
    "abbr": "HST",
    "offset": -10,
    "isdst": false,
    "text": "UTC-10:00",
    "utc": [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti"
    ]
  },
  {
    "value": "America/Anchorage",
    "abbr": "AKDT",
    "offset": -9,
    "isdst": true,
    "text": "UTC-09:00",
    "utc": [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ]
  },
  {
    "value": "America/Vancouver",
    "abbr": "PST",
    "offset": -8,
    "isdst": false,
    "text": "UTC-08:00",
    "utc": [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "PST8PDT"
    ]
  },
  {
    "value": "America/Los_Angeles",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "UTC-07:00",
    "utc": [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver"
    ]
  },
  {
    "value": "America/Denver",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "UTC-07:00",
    "utc": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ]
  },
  {
    "value": "America/Chicago",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "UTC-06:00",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    "value": "America/Caracas",
    "abbr": "VST",
    "offset": -4.5,
    "isdst": false,
    "text": "UTC-04:30",
    "utc": [
      "America/Caracas"
    ]
  },
  {
    "value": "America/Detroit",
    "abbr": "EDT",
    "offset": -4,
    "isdst": true,
    "text": "UTC-04:00",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto"
    ]
  },
  {
    "value": "America/Glace_Bay",
    "abbr": "ADT",
    "offset": -3,
    "isdst": true,
    "text": "UTC-04:00",
    "utc": [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda"
    ]
  },
  {
    "value": "America/St_Johns",
    "abbr": "NDT",
    "offset": -2.5,
    "isdst": true,
    "text": "UTC-03:30",
    "utc": [
      "America/St_Johns"
    ]
  },
  {
    "value": "Atlantic/South_Georgia",
    "abbr": "U",
    "offset": -2,
    "isdst": false,
    "text": "UTC-02:00",
    "utc": [
      "America/Noronha",
      "Atlantic/South_Georgia",
      "Etc/GMT+2"
    ]
  },
  {
    "value": "Atlantic/Cape_Verde",
    "abbr": "MDT",
    "offset": -1,
    "isdst": true,
    "text": "UTC-01:00",
    "utc": []
  },
  {
    "value": "Europe/London",
    "abbr": "GMT",
    "offset": 0,
    "isdst": false,
    "text": "UTC+00:00",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "value": "Africa/Lagos",
    "abbr": "WCAST",
    "offset": 1,
    "isdst": false,
    "text": "UTC+01:00",
    "utc": [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1"
    ]
  },
  {
    "value": "Africa/Harare",
    "abbr": "SAST",
    "offset": 2,
    "isdst": false,
    "text": "UTC+02:00",
    "utc": [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2"
    ]
  },
  {
    "value": "Africa/Nairobi",
    "abbr": "EAST",
    "offset": 3,
    "isdst": false,
    "text": "UTC+03:00",
    "utc": [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte"
    ]
  },
  {
    "value": "Asia/Dubai",
    "abbr": "AST",
    "offset": 4,
    "isdst": false,
    "text": "UTC+04:00",
    "utc": [
      "Asia/Dubai",
      "Asia/Muscat",
      "Etc/GMT-4"
    ]
  },
  {
    "value": "Asia/Kabul",
    "abbr": "AST",
    "offset": 4.5,
    "isdst": false,
    "text": "UTC+04:30",
    "utc": [
      "Asia/Dubai",
      "Asia/Muscat",
      "Etc/GMT-4"
    ]
  },
  {
    "value": "Asia/Karachi",
    "abbr": "PKT",
    "offset": 5,
    "isdst": false,
    "text": "UTC+05:00",
    "utc": [
      "Asia/Karachi"
    ]
  },
  {
    "value": "Asia/Kolkata",
    "abbr": "SLST",
    "offset": 5.5,
    "isdst": false,
    "text": "UTC+05:30",
    "utc": [
      "Asia/Colombo"
    ]
  },
  {
    "value": "Asia/Kathmandu",
    "abbr": "NST",
    "offset": 5.75,
    "isdst": false,
    "text": "UTC+05:45",
    "utc": [
      "Asia/Kathmandu"
    ]
  },
  {
    "value": "Asia/Dhaka",
    "abbr": "BST",
    "offset": 6,
    "isdst": false,
    "text": "UTC+06:00",
    "utc": [
      "Asia/Dhaka",
      "Asia/Thimphu"
    ]
  },
  {
    "value": "Asia/Yangon",
    "abbr": "MST",
    "offset": 6.5,
    "isdst": false,
    "text": "UTC+06:30",
    "utc": [
      "Asia/Rangoon",
      "Indian/Cocos"
    ]
  },
  {
    "value": "Asia/Bangkok",
    "abbr": "NCAST",
    "offset": 7,
    "isdst": false,
    "text": "UTC+07:00",
    "utc": [
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk"
    ]
  },
  {
    "value": "Asia/Singapore",
    "abbr": "NAEST",
    "offset": 8,
    "isdst": false,
    "text": "UTC+08:00",
    "utc": [
      "Asia/Irkutsk"
    ]
  },
  {
    "value": "Asia/Tokyo",
    "abbr": "KST",
    "offset": 9,
    "isdst": false,
    "text": "UTC+09:00",
    "utc": [
      "Asia/Pyongyang",
      "Asia/Seoul"
    ]
  },
  {
    "value": "Australia/Adelaide",
    "abbr": "CAST",
    "offset": 9.5,
    "isdst": false,
    "text": "UTC+09:30",
    "utc": [
      "Australia/Adelaide",
      "Australia/Broken_Hill"
    ]
  },
  {
    "value": "Australia/Sydney",
    "abbr": "TST",
    "offset": 10,
    "isdst": false,
    "text": "UTC+10:00",
    "utc": [
      "Australia/Currie",
      "Australia/Hobart"
    ]
  },
  {
    "value": "Asia/Magadan",
    "abbr": "VST",
    "offset": 11,
    "isdst": false,
    "text": "UTC+11:00",
    "utc": [
      "Asia/Sakhalin",
      "Asia/Ust-Nera",
      "Asia/Vladivostok"
    ]
  },
  {
    "value": "Pacific/Auckland",
    "abbr": "MST",
    "offset": 12,
    "isdst": false,
    "text": "UTC+12:00",
    "utc": [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk"
    ]
  },
  {
    "value": "Pacific/Apia",
    "abbr": "SST",
    "offset": 13,
    "isdst": false,
    "text": "UTC+13:00",
    "utc": [
      "Pacific/Apia"
    ]
  }
];