export const colors = {

  black: '#000000',
  white: '#ffffff',
  core: '#162234',
  
 /** 
  *  The primary color is the "brand" color, 
  * and is used across all interactive elements such as buttons, links, inputs, etc. 
  */ 
  primary: {
    5: "#F2F7FF",
    25: "#FCFAFF",
    50: "#F9F5FF",
    100: "#F4EBFF",
    200: "#B0CDFF",
    300: '#D6BBFB',
    400: '#B692F6',
    500: '#004CCC',
    600: '#7F56D9',
    700: '#003999',
    800: '#53389E',
    900: '#42307D'
  },

  secondary: {
    25: "#FFFAF5",
    50: "#FFF7F0",
    100: "#FFF2E6",
    200: "#FFD7B0",
    300: '#FFBB79',
    400: '#FFA043',
    500: '#FF840C',
    600: '#CC6500',
    700: '#994C00',
    800: '#663200',
    900: '#3D1E00'
  },

  grey: {
    25: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F2F4F7",
    200: "#EAECF0",
    300: '#D0D5DD',
    350: '#d0d5dd87',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828'
  },

  error: {
    50: "#FEF3F2",
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A'
  },

  warning: {
    50: '#FFFAEB',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E'
  },

  success: {
    50: "#ECFDF3",
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31'
  },

  
};
