export interface ISelectOptionObject {
  name: string;
  value: string | number;
}

export const transformToSelectOptionList = (array: string[]) => {
  let formattedList: ISelectOptionObject[] = [];

  array.map((item) => {
    const list = {
      name: item,
      value: item,
    };
    formattedList = [...formattedList, list];
  });

  return formattedList;
};

export const transformTzToSelectOptionList = (array: any[]) => {
  let formattedList: ISelectOptionObject[] = [];

  array.map((item) => {
    const list = {
      name: `${item?.value} (${item?.text})`,
      value: `${item?.value} (${item?.text})`,
    };
    formattedList = [...formattedList, list];
  });

  return formattedList;
};

export const transformCurrenciesToSelectOptionList = (array: any[]) => {
  let formattedList: ISelectOptionObject[] = [];

  array.map((item) => {
    const list = {
      name: `${item?.name} (${item?.code})`,
      value: `${item?.id}`,
    };
    formattedList = [...formattedList, list];
  });

  return formattedList;
};

export const transformBanksToSelectOptionList = (array: any[]) => {
  let formattedList: ISelectOptionObject[] = [];

  array?.map((item) => {
    const list = {
      name: `${item?.bank_name}`,
      value: `${item?.bank_name}`,
    };
    return formattedList = [...formattedList, list];
  });

  return formattedList;
};