import { makeStyles } from '@material-ui/core/styles';

export const useSingleSelectStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },

    helperText: {
        height: 20,
        whiteSpace: 'pre-wrap',
    },
});
