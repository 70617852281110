export type ACCOUNTTYPE = "auctioneer" | "auctionee";

const setCurrentAccount = (accountType: ACCOUNTTYPE) => {
  localStorage.setItem("currentUserType", accountType);
};

const getCurrentAccount = () => {
  return localStorage.getItem("currentUserType") as ACCOUNTTYPE;
};

const removeCurrentAccount = () => {
  return localStorage.removeItem("currentUserType");
};

export const useCurrentAccount = () => {
  return {
    setCurrentAccount,
    getCurrentAccount,
    removeCurrentAccount,
  };
};
