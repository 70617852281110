import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
} from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as RaydaLogo } from "@/bidda/_shared/assets/icons/bidda-logo.svg";
import raydaLogo from "@/bidda/_shared/assets/icons/bidda-logo.svg";

interface Props {
  onMobileOpen: () => void;
  mobileOpen: boolean;
}
const MobileDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onMobileOpen,
  mobileOpen,
}) => {
  const handleDrawerToggle = () => {
    onMobileOpen();
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#fff",
          border: "none",
          boxShadow: "none",
          display: {
            xs: "block",
            sm: "block",
            md: "none",
          },
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon sx={{ color: "#667085" }} />
            </IconButton>
            <img src={raydaLogo} width={100} />
          </Box>
        </Toolbar>

        <Divider />
      </AppBar>

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          maxHeight: "100vh",
        }}
      >
        {children}
      </Drawer>
    </div>
  );
};

export default MobileDrawer;
