import React from "react";

import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import { customTheme } from "./theme/customTheme";
import { CssBaseline } from "@material-ui/core";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AppRouter from "@/router";

const App: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
