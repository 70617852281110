import { Button, FormLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { ImFilePicture } from "react-icons/im";
import { BsCheck2 } from "react-icons/bs";
import ImageUpload from "react-images-uploading";
import MuiPhoneNumber from "material-ui-phone-number";
import cn from "classnames";
import { ReactComponent as FileIcon } from "@/marketplace/assets/icons/file-icon.svg";
import { ReactComponent as UploadIcon } from "@/marketplace/assets/icons/upload-icon.svg";
import PhoneInput from "react-phone-number-input";

interface Props {
  onUpdateStage: () => void;
  onCancel: () => void;
}
const ProfileUpdate: React.FC<Props> = ({ onCancel, onUpdateStage }) => {
  const [images, setImages] = useState([]);
  const [value, setValue] = useState("");

  return (
    <div className="relative bg-gray-50 h-screen">
      <div className="px-4 pt-5">
        <IoChevronBackOutline
          className="text-xl cursor-pointer"
          onClick={onCancel}
        />
      </div>

      <div className="pb-4 pt-8 px-4">
        <h2 className="font-medium text-2xl">My Profile</h2>
        <span className="text-gray-700 text-lg">
          Please update your profile details
        </span>
      </div>

      <div className="p-4 flex flex-col gap-y-6">
        <div>
          <FormLabel className="mt-1">Company name</FormLabel>
          <TextField placeholder="Enter your company name" />
        </div>

        <div>
          <FormLabel className="mt-1">Phone number</FormLabel>
          <PhoneInput
            placeholder="Enter phone number"
            defaultCountry="NG"
            value={value}
            onChange={(value) => setValue(value as string)}
          />
        </div>

        <div>
          <div className="pb-2">
            <FormLabel className="mt-1">
              Upload your profile picture (Optional)
            </FormLabel>
          </div>

          <div className="App">
            <ImageUpload
              multiple={false}
              maxFileSize={1 * 1024 * 1024}
              value={images}
              onChange={(value) => {
                setImages(value as any);
              }}
            >
              {({ imageList, onImageUpload, isDragging, dragProps }) => (
                <div className="flex flex-col gap-y-4">
                  <div
                    className={cn(
                      "py-4 px-6 border border-gray-300 bg-white rounded-xl",
                      {
                        "border-blue-600": isDragging,
                        "border-gray-300": !isDragging,
                      }
                    )}
                    {...dragProps}
                  >
                    <div onClick={onImageUpload}>
                      <div className="grid place-content-center pb-4">
                        {imageList?.length === 0 && <FileIcon />}
                        {imageList?.length > 0 && <UploadIcon />}
                      </div>
                      <div>
                        <span className="text-blue-700 font-semibold text-sm">
                          Click to upload
                        </span>{" "}
                        <span className="text-gray-600 font-normal text-sm">
                          or drag and drop
                        </span>
                      </div>
                      <span className="block text-gray-600 text-xs">
                        SVG, PNG, JPG or GIF
                      </span>
                    </div>
                  </div>

                  {imageList?.length > 0 && (
                    <div
                      className="grid grid-cols-3 items-center p-4 bg-white border border-blue-600 rounded-xl"
                      onClick={onImageUpload}
                    >
                      <div className="h-[74px] w-[74px] border rounded-full p-1 shadow-sm">
                        <img
                          src={imageList[0].dataURL}
                          alt="preview"
                          className="w-full object-cover h-full rounded-full shadow-2xl"
                        />
                      </div>
                      <div className="flex flex-col items-start">
                        <span className="text-gray-700 font-medium text-sm">
                          {imageList[0].file?.name}
                        </span>
                        <span className="text-gray-600 font-normal text-sm">
                          {(imageList[0].file?.size! / (1024 * 1024)).toFixed(
                            2
                          )}{" "}
                          MB
                        </span>
                      </div>
                      <div className="justify-self-end">
                        <div className="bg-blue-600 rounded-full">
                          <BsCheck2 className="text-white text-2xl p-1" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </ImageUpload>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 px-4 w-full py-3 bg-white shadow-lg">
        <Button variant="contained" className="w-full" onClick={onUpdateStage}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ProfileUpdate;
