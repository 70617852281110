import { Button, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { countries, lookup, regions } from "country-data-list";

interface Props {
  onUpdateStage: () => void;
  onCancel: () => void;
}

const Address: React.FC<Props> = ({ onUpdateStage, onCancel }) => {
  const [country, setCountry] = useState("");
  //api.facts.ng/v1/states/lagos

  return (
    <div className="relative bg-gray-50 h-screen">
      <div className="px-4 pt-5">
        <IoChevronBackOutline
          className="text-xl cursor-pointer"
          onClick={onCancel}
        />
      </div>

      <div className="pb-4 pt-8 px-4">
        <h2 className="font-medium text-2xl">Add Address</h2>
        <span className="text-gray-700 text-lg">
          Please your address for pick up
        </span>
      </div>

      <div className="p-4 flex flex-col gap-y-6">
        <div className="flex flex-col">
          <FormLabel className="mb-1">Country</FormLabel>
          <Select value={country} onChange={(e) => setCountry(e.target.value)}>
            <MenuItem value={"nigeria"}>Nigeria</MenuItem>
          </Select>
        </div>

        <div className="flex flex-col">
          <FormLabel className="mb-1">Address</FormLabel>
          <TextField placeholder="Enter address" />
        </div>
      </div>

      <div className="fixed bottom-0 px-4 w-full py-3 bg-white shadow-lg">
        <Button variant="contained" className="w-full" onClick={onUpdateStage}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default Address;
