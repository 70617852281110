import { makeStyles } from "@material-ui/core";
import { colors } from "@/bidda/theme/colors";

export const useBillingStyles = makeStyles((theme) => ({
  form: {
    paddingTop: theme.spacing(3),
  },
  activeBankDetails: {
    border: `2px solid ${colors.primary[500]}`,
    padding: "8px",
    borderRadius: "12px",
    backgroundColor: colors.primary[50],
  },
  billingBody: {
    padding: theme.spacing(2),
  },
  textField: {
    border: `1px solid ${colors.grey[300]}`,
    borderRadius: "8px",
    backgroundColor: colors.white,
    color: colors.grey[900],
  },
  bankDetails: {
    border: `1px solid ${colors.grey[200]}`,
    padding: "8px",
    borderRadius: "12px",
    backgroundColor: colors.white,
  },
  activeBankName: {
    color: colors.primary[700],
    fontWeight: 500,
  },
  activeBankNumber: {
    color: colors.primary[500],
  },
  bankDefault: {
    color: colors.primary[500],
    fontWeight: 600,
    textTransform: "none",
  },
  bankEdit: {
    color: colors.primary[700],
    fontWeight: 600,
    textTransform: "none",
  },
  bankDetailsContainer: {
    paddingTop: theme.spacing(2),
  },
  textBtn: {
    padding: "0px",
    color: colors.grey[600],
    fontWeight: 600,
    textTransform: "none",
  },
  tableIcons: {
    justifyContent: "space-between",
    padding: "15px 30px",
    alignItem: "center",
    textAlign: "center",
  },
  inputBase: {
    border: `1px solid ${colors.grey[300]}`,
    borderRadius: "8px",
    outline: "none",
  },
  bankDetailContent: {
    justifyContent: "space-between",
  },
  checkbox: {
    color: colors.primary[500],
  },
  tableBtn: {
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    color: colors.grey[700],
    border: `1px solid ${colors.grey[300]}`,
    "&:hover": {
      backgroundColor: colors.grey[25],
      border: `1px solid ${colors.grey[25]}`,
    },
  },
  tableHead: {
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
    },
  },
  tableHeadItems: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "10px",
      paddingTop: "10px",
    },
  },
  toggleButton: {
    textTransform: "none",
    fontWeight: 600,
  },
  pending: {
    color: colors.warning[700],
    border: `1px solid ${colors.warning[700]}`,
    alignItems: "center",
    fontSize: "small",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "18px",
  },
  paid: {
    color: colors.success[700],
    border: `1px solid ${colors.success[700]}`,
    alignItems: "center",
    fontSize: "small",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "18px",
  },
  cancelled: {
    color: colors.error[700],
    border: `1px solid ${colors.error[700]}`,
    alignItems: "center",
    fontSize: "small",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "18px",
  },
  avatarCell: {
    display: "flex",
    justifyContent: "space-around",
  },
  table: {
    padding: "10px 0px",
    borderRadius: "8px",
    alignItems: "center",
  },
}));
