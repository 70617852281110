import { Button, Checkbox, FormLabel, TextField } from "@mui/material";
import React from "react";
import { CiFacebook } from "react-icons/ci";
import { FcGoogle } from "react-icons/fc";

const SignUp: React.FC<{ setIsOtpModalOpen: () => void }> = ({
  setIsOtpModalOpen,
}) => {
  return (
    <div className="bg-gray-50 h-screen">
      <div className="pb-4 pt-10 px-4">
        <h2 className="font-medium text-2xl">Create an account</h2>
        <span className="text-gray-700 text-base">
          Please enter your details
        </span>
      </div>
      <div className="p-4">
        <div className="pb-[20px]">
          <FormLabel>Email</FormLabel>
          <TextField placeholder="Enter your email" />
        </div>
        <div className="pb-[20px]">
          <FormLabel>Password</FormLabel>
          <TextField />
          <span className="text-sm font-normal text-gray-400">
            Must be at least 8 characters
          </span>
        </div>

        <div className="flex gap-2 pb-4">
          <Checkbox className="p-0" />{" "}
          <div>
            <span className="text-gray-500 font-normal text-sm">
              I agree to the
            </span>{" "}
            <span className="text-blue-700 font-normal text-sm">
              terms and conditions
            </span>
          </div>
        </div>

        <div>
          <Button
            variant="contained"
            className="w-full"
            onClick={() => setIsOtpModalOpen()}
          >
            Create an account
          </Button>
        </div>

        <div className="text-center py-6">OR</div>

        <div className="flex flex-col gap-y-4 pb-6">
          <Button
            startIcon={<FcGoogle />}
            variant="outlined"
            className="w-full"
          >
            Sign in with Google
          </Button>
          <Button
            startIcon={
              <CiFacebook className=" text-blue-700 bg-white rounded-full" />
            }
            variant="contained"
            className="w-full !bg-[#1877F2]"
          >
            Sign in with Facebook
          </Button>
        </div>

        <div className="text-center">
          <span className="text-gray-500 font-normal text-sm">
            Already have an account?
          </span>{" "}
          <span className="text-blue-700 text-sm font-medium">Sign in</span>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
