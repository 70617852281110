import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { ExtendedRoute } from "@/bidda/_lib/routing/types";
import { SETTINGS_PATHS } from "@/bidda/routerPaths/setting";
import { COMPANY_PATHS } from "@/bidda/routerPaths/company";
import LoadingScreen from "@/bidda/_shared/components/LoadingScreen";
import AUCTIONEE_PATHS from "@/bidda/routerPaths/auctionee";
import AUCTIONEER_PATHS from "@/bidda/routerPaths/auctioneer";
import AuthRequired from "@/bidda/pages/auth/component/AuthRequired";
import AuthNotRequired from "@/bidda/pages/auth/component/AuthNotRequired";
import SettingsPage from "@/bidda/pages/settings/components/SettingsPage";
import { AUTH_PATHS } from "@/bidda/routerPaths/auth";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const AuthLogin = Loadable(lazy(() => import("@/bidda/pages/auth/login")));
const ForgotPassword = Loadable(
  lazy(() => import("@/bidda/pages/auth/component/ForgotPassword"))
);
const SetNewPassword = Loadable(
  lazy(() => import("@/bidda/pages/auth/component/SetNewPassword"))
);
const CompanyOboarding = Loadable(
  lazy(() => import("@/bidda/pages/auth/component/CompanyOnboarding"))
);
const AuctioneeRegister = Loadable(
  lazy(() => import("@/bidda/pages/auctionee/AuctioneeRegister"))
);

// AUCTIONEER PAGES
const AuctioneerDashboard = Loadable(
  lazy(() => import("@/bidda/pages/auctioneer/Dashboard"))
);
const Auctions = Loadable(
  lazy(() => import("@/bidda/pages/auctioneer/Auctions"))
);
const AuctionPreview = Loadable(
  lazy(() => import("@/bidda/pages/auctioneer/AuctionPreview"))
);
const AuctionCreate = Loadable(
  lazy(() => import("@/bidda/pages/auctioneer/AuctionCreate"))
);
const AuctionAssets = Loadable(
  lazy(() => import("@/bidda/pages/auctioneer/AuctionAssetsPreview"))
);
const AuctionReport = Loadable(
  lazy(() => import("@/bidda/pages/auctioneer/AuctionReport"))
);
const ContactList = Loadable(
  lazy(() => import("@/bidda/pages/auctioneer/ContactList"))
);
const AuctionView = Loadable(
  lazy(() => import("@/bidda/pages/auctioneer/AuctionView"))
);

// AUCTIONEE PAGES
const AuctioneeDashboard = Loadable(
  lazy(() => import("@/bidda/pages/auctionee/Dashboard"))
);
const AuctionResult = Loadable(
  lazy(() => import("@/bidda/pages/auctionee/AuctionResult"))
);
const AllAuctions = Loadable(
  lazy(() => import("@/bidda/pages/auctionee/Auctions"))
);

const publicRoutes: ExtendedRoute[] = [
  {
    path: "/",
    exact: true,
    private: true,
    element: <AuthNotRequired />,
    children: [
      {
        path: AUTH_PATHS.ROOT,
        element: <AuthLogin />,
      },
      {
        path: AUTH_PATHS.LOGIN,
        element: <AuthLogin />,
      },
      {
        path: AUTH_PATHS.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: AUTH_PATHS.SET_NEW_PASSWORD,
        element: <SetNewPassword />,
      },
      {
        path: COMPANY_PATHS.COMPANY_INVITATION,
        element: <CompanyOboarding />,
      },
      {
        path: SETTINGS_PATHS.SETTINGS,
        element: <SettingsPage />,
      },
      {
        path: AUCTIONEE_PATHS.REGISTER,
        element: <AuctioneeRegister />,
      },
    ],
  },
];

const privateRoutes: ExtendedRoute[] = [
  {
    path: "/",
    exact: true,
    private: true,
    element: <AuthRequired />,
    children: [
      // AUCTIONEER ROUTES
      {
        path: AUCTIONEER_PATHS.DASHBOARD,
        element: <AuctioneerDashboard />,
      },
      {
        path: AUCTIONEER_PATHS.AUCTIONS,
        element: <Auctions />,
      },
      {
        path: AUCTIONEER_PATHS.PREVIEW_AUCTION,
        element: <AuctionPreview />,
      },
      {
        path: AUCTIONEER_PATHS.CREATE_AUCTION,
        element: <AuctionCreate />,
      },
      {
        path: AUCTIONEER_PATHS.PREVIEW_AUCTION_ASSETS,
        element: <AuctionAssets />,
      },
      {
        path: AUCTIONEER_PATHS.AUCTION_REPORT,
        element: <AuctionReport />,
      },
      {
        path: AUCTIONEER_PATHS.CONTACT_LIST,
        element: <ContactList />,
      },
      {
        path: AUCTIONEER_PATHS.VIEW_AUCTION,
        element: <AuctionView />,
      },

      // AUCTIONEE ROUTES
      {
        path: AUCTIONEE_PATHS.DASHBOARD,
        element: <AuctioneeDashboard />,
      },
      {
        path: AUCTIONEE_PATHS.RESULT_AUCTION,
        element: <AuctionResult />,
      },
      {
        path: AUCTIONEE_PATHS.VIEW_AUCTION,
        element: <AuctionView />,
      },
      {
        path: AUCTIONEE_PATHS.ALL_AUCTIONS,
        element: <AllAuctions />,
      },
    ],
  },
];

const biddaRoutes: ExtendedRoute[] = [...publicRoutes, ...privateRoutes];

export default biddaRoutes;
