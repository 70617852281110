import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Divider, Grid, Radio, Typography } from "@material-ui/core";

import cn from "classnames";
import { ReactComponent as SwitchSvg } from "@/bidda/_shared/assets/icons/switch-account-icon.svg";
import { Avatar, Button } from "@mui/material";
import { useSwitchAccountModalStyles } from "./style";
import { useNavigate } from "react-router-dom";
import AUCTIONEE_PATHS from "@/bidda/routerPaths/auctionee";
import AUCTIONEER_PATHS from "@/bidda/routerPaths/auctioneer";
import { AuthUserDetailProps } from "@/bidda/_shared/hooks/useAuthUserDetail";
import {
  ACCOUNTTYPE,
  useCurrentAccount,
} from "@/bidda/_shared/hooks/useCurrentAccount";

type SwitchAccountModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  authUser: AuthUserDetailProps;
};

const SwitchAccountModal: FC<SwitchAccountModalProps> = ({
  isModalOpen,
  onClose,
  authUser,
}) => {
  const navigate = useNavigate();

  const { getCurrentAccount, setCurrentAccount } = useCurrentAccount();
  const currentAccount = getCurrentAccount();

  const [account, setAccount] = useState(currentAccount);

  const { getUserInitials, getUserFullname, getUserDetails } = authUser;
  const { user } = getUserDetails();

  const modalStyles = useSwitchAccountModalStyles();

  const handleSwitchAccount = () => {
    setCurrentAccount(account);
    account === "auctionee" && navigate(AUCTIONEE_PATHS.DASHBOARD);
    account === "auctioneer" && navigate(AUCTIONEER_PATHS.DASHBOARD);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccount(event.target.value as ACCOUNTTYPE);
  };

  return (
    <Box>
      <Modal
        open={isModalOpen}
        onClose={onClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className={cn(modalStyles.auctionFeeModal)}
          sx={{ width: { xs: "70%", md: "40%" } }}
        >
          <Grid container item className={cn(modalStyles.modalBody)}>
            <Box className={modalStyles.switchIconBox}>
              <SwitchSvg className={modalStyles.switchIcon} />
            </Box>
            <Box className={modalStyles.cardBody}>
              <Typography
                variant={"h4"}
                className={modalStyles.modalHeaderText}
              >
                Switch Account
              </Typography>
              <Typography variant={"body2"}>
                Switch between multiple accounts with ease using our Switch
                Account feature
              </Typography>

              <Box
                sx={{ display: "flex" }}
                className={cn(
                  modalStyles.radioBox,
                  account === "auctionee" && modalStyles.active
                )}
              >
                <Box sx={{ width: "15%" }}>
                  <Avatar
                    sx={{
                      background: "#F2F4F7",
                      color: "#475467",
                      fontSize: "16px",
                    }}
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {getUserInitials()}
                    </Typography>
                  </Avatar>
                </Box>
                <Box sx={{ width: "85%" }}>
                  <Typography variant={"body2"} className={modalStyles.account}>
                    {user?.username}
                  </Typography>
                  <Typography
                    variant={"body2"}
                    className={cn(
                      account === "auctionee" && modalStyles.blueText
                    )}
                  >
                    This is your user account
                  </Typography>
                </Box>
                <Box sx={{ float: "right" }}>
                  <Radio
                    checked={account === "auctionee"}
                    onChange={handleChange}
                    value="auctionee"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                    size="small"
                    color="primary"
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex" }}
                className={cn(
                  modalStyles.radioBox,
                  account === "auctioneer" && modalStyles.active
                )}
              >
                <Box sx={{ width: "15%" }}>
                  <Avatar
                    sx={{
                      // margin: "10px",
                      background: "#F2F4F7",
                      color: "#475467",
                      fontSize: "16px",
                    }}
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {getUserInitials()}
                    </Typography>
                  </Avatar>
                </Box>
                <Box sx={{ width: "85%" }}>
                  <Typography variant={"body2"} className={modalStyles.account}>
                    {getUserFullname()}
                  </Typography>
                  <Typography
                    variant={"body2"}
                    className={cn(
                      account === "auctioneer" && modalStyles.blueText
                    )}
                  >
                    This is your admin account
                  </Typography>
                </Box>
                <Box sx={{ float: "right" }}>
                  <Radio
                    checked={account === "auctioneer"}
                    onChange={handleChange}
                    value="auctioneer"
                    name="radio-buttons"
                    size="small"
                    color="primary"
                  />
                </Box>
              </Box>
            </Box>

            <Grid item container>
              <Grid item md={6} className={modalStyles.btnHL}>
                <Button
                  size="large"
                  onClick={onClose}
                  style={{
                    fontSize: "14px !important",
                    color: "#475467",
                    width: "100%",
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item md={6} className={modalStyles.btnHR}>
                <Button
                  onClick={handleSwitchAccount}
                  size="large"
                  style={{ fontSize: "14px !important", width: "100%" }}
                  variant="contained"
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default SwitchAccountModal;
