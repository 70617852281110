import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import { ReactComponent as HomeSvg } from "@/bidda/_shared/assets/icons/home-icon.svg";
import { Typography } from "@material-ui/core";
import cn from "classnames";
import { useSideMenuStyles } from "../../styles";
import { Instance } from "@popperjs/core";
import { ReactComponent as NavSvg } from "@/bidda/_shared/assets/icons/nav_item_Icon.svg";
import { ReactComponent as SupportSvg } from "@/bidda/_shared/assets/icons/support_icon.svg";
import { ReactComponent as SettingSvg } from "@/bidda/_shared/assets/icons/setting-icon.svg";
import { ReactComponent as LogoutSvg } from "@/bidda/_shared/assets/icons/logout-icon.svg";
import { ReactComponent as SwitchSvg } from "@/bidda/_shared/assets/icons/switch-account-icon.svg";
import { useIsAuctionee } from "@/bidda/_shared/hooks/useIsAuctionee";
import { useIsAuctioneer } from "@/bidda/_shared/hooks/useIsAuctioneer";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import useLogOut from "@/bidda/_shared/hooks/useLogOut";
import AUCTIONEE_PATHS from "@/bidda/routerPaths/auctionee";
import { useAuthUserDetail } from "@/bidda/_shared/hooks/useAuthUserDetail";
import { useCurrentAccount } from "@/bidda/_shared/hooks/useCurrentAccount";
import MARKETPLACE_PATHS from "@/marketplace/routerPaths/marketplace";

interface Props {
  handleDrawerOpen: () => void;
  handleSwitchAccount: () => void;
  handleShowLogOut?: () => void;
  isDesktopOpen?: boolean;
  isMobileOpen?: boolean;
}

const DrawerContent: React.FC<Props> = ({
  handleDrawerOpen,
  handleSwitchAccount,
  handleShowLogOut,
  isDesktopOpen,
  isMobileOpen,
}) => {
  const classes = useSideMenuStyles();

  const { isAuctionee } = useIsAuctionee();
  const { isAuctioneer } = useIsAuctioneer();

  const { getUserInitials, getUserFullname, getUserDetails } =
    useAuthUserDetail();
  const { user } = getUserDetails();
  const { logOut } = useLogOut();
  const { removeCurrentAccount } = useCurrentAccount();

  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = useRef<Instance>(null);
  const areaRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const navLinkClassName = ({
    isActive,
  }: {
    isActive: boolean;
  }): Record<string, string> => {
    return {
      textDecoration: "none",
    };
  };

  return (
    <React.Fragment>
      <List sx={{ padding: "0px 10px", marginTop: "50px", height: "100vh" }}>
        <Toolbar sx={{ display: { xs: "none", sm: "block" } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(isDesktopOpen && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>

        <ListItem key="Home" disablePadding sx={{ display: "block" }}>
          <NavLink style={navLinkClassName} to={AUCTIONEE_PATHS.DASHBOARD}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent:
                  isDesktopOpen || isMobileOpen ? "initial" : "center",
                px: 2.5,
                fontSize: "16px",
                fontWeight: 600,
                textDecoration: "none !important",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isDesktopOpen || isMobileOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeSvg />
              </ListItemIcon>
              <Typography
                className={cn(
                  classes.listItemText,
                  (isDesktopOpen || isMobileOpen) && classes.showText
                )}
              >
                Home
              </Typography>
            </ListItemButton>
          </NavLink>
        </ListItem>

        <ListItem key="Marketplace" disablePadding sx={{ display: "block" }}>
          <NavLink style={navLinkClassName} to={MARKETPLACE_PATHS.HOME}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent:
                  isDesktopOpen || isMobileOpen ? "initial" : "center",
                px: 2.5,
                fontSize: "16px",
                fontWeight: 600,
                textDecoration: "none !important",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isDesktopOpen || isMobileOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FavoriteBorderIcon />
              </ListItemIcon>
              <Typography
                className={cn(
                  classes.listItemText,
                  (isDesktopOpen || isMobileOpen) && classes.showText
                )}
              >
                Marketplace
              </Typography>
            </ListItemButton>
          </NavLink>
        </ListItem>

        <ListItem key="Auctions" disablePadding sx={{ display: "block" }}>
          <NavLink style={navLinkClassName} to={AUCTIONEE_PATHS.ALL_AUCTIONS}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent:
                  isDesktopOpen || isMobileOpen ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isDesktopOpen || isMobileOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <NavSvg />
              </ListItemIcon>
              <Typography
                className={cn(
                  classes.listItemText,
                  (isDesktopOpen || isMobileOpen) && classes.showText
                )}
              >
                Auctions
              </Typography>
            </ListItemButton>
          </NavLink>
        </ListItem>

        {/* <ListItem key="Wishlist" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent:
                isDesktopOpen || isMobileOpen ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDesktopOpen || isMobileOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <FavoriteBorderIcon />
            </ListItemIcon>
            <Typography
              className={cn(
                classes.listItemText,
                (isDesktopOpen || isMobileOpen) && classes.showText
              )}
            >
              Wishlist
            </Typography>
          </ListItemButton>
        </ListItem>

        <ListItem key="Support" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent:
                isDesktopOpen || isMobileOpen ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDesktopOpen || isMobileOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <SupportSvg />
            </ListItemIcon>
            <Typography
              className={cn(
                classes.listItemText,
                (isDesktopOpen || isMobileOpen) && classes.showText
              )}
            >
              Support
            </Typography>
          </ListItemButton>
        </ListItem>

        <ListItem key="settings" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent:
                isDesktopOpen || isMobileOpen ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDesktopOpen || isMobileOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <SettingSvg />
            </ListItemIcon>
            <Typography
              className={cn(
                classes.listItemText,
                (isDesktopOpen || isMobileOpen) && classes.showText
              )}
            >
              Settings
            </Typography>
          </ListItemButton>
        </ListItem> */}
      </List>

      <List sx={{ position: "relative" }}>
        <ListItem
          key="Bottom"
          disablePadding
          sx={{
            width: "100%",
            position: "absolute",
            overflow: "hidden",
            bottom: 10,
            padding: "0px 8px",
            zIndex: 0,
          }}
        >
          <Box
            onClick={() => handleDrawerOpen()}
            sx={{
              minHeight: 48,
              justifyContent:
                isDesktopOpen || isMobileOpen ? "initial" : "center",
              padding: "0px 13px",
              display: "flex",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            className={cn(classes.listItemTextBtn)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDesktopOpen ? 1 : isMobileOpen ? 0 : "auto",
                justifyContent: "center",
              }}
            >
              <Avatar
                sx={{
                  // margin: "10px",
                  background: "#F2F4F7",
                  color: "#475467",
                  fontSize: "16px",
                  display: {
                    xs: "none",
                    sm: "inherit",
                  },
                }}
              >
                <Typography style={{ fontWeight: 500 }}>
                  {getUserInitials()}
                </Typography>
              </Avatar>
            </ListItemIcon>
            {(isMobileOpen || isDesktopOpen) && (
              <Grid>
                <Typography
                  variant={"body2"}
                  className={cn(classes.listItemTextBottom)}
                >
                  <Box component={"span"} className={classes.userName}>
                    {getUserFullname()}
                  </Box>
                  {isAuctionee && isAuctioneer && (
                    <Box component={"span"}>
                      <Tooltip
                        // sx={{ background: "#18181B", color: "#fff"}}
                        title="Switch Account"
                        placement="top"
                        arrow
                        PopperProps={{
                          popperRef,
                          anchorEl: {
                            getBoundingClientRect: () => {
                              return new DOMRect(
                                positionRef.current.x,
                                areaRef.current!.getBoundingClientRect().y,
                                0,
                                0
                              );
                            },
                          },
                        }}
                      >
                        <Box
                          component={"span"}
                          ref={areaRef}
                          onMouseMove={handleMouseMove}
                        >
                          <SwitchSvg
                            className={classes.switchIcon}
                            onClick={handleSwitchAccount}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  )}

                  <Box component={"span"}>
                    <LogoutSvg
                      className={classes.LogoutIcon}
                      // onClick={() => {
                      //   logOut();
                      //   removeCurrentAccount();
                      // }}
                      onClick={handleShowLogOut}
                    />
                  </Box>
                </Typography>
                <Typography
                  variant={"subtitle2"}
                  className={cn(classes.listItemTextSub)}
                >
                  {user?.email}
                </Typography>
              </Grid>
            )}
          </Box>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

export default DrawerContent;
