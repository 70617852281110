import { makeStyles, Theme } from '@material-ui/core/styles';
import { IFormProps } from './index';

export const useFormStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
}));
