import AUCTIONEER_PATHS from "@/bidda/routerPaths/auctioneer";
import { FC } from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";

const AuthNotRequired: FC = () => {
  const storage = localStorage?.getItem("authState");

  const { token } = !!storage ? JSON.parse(storage) : "";

  const location = useLocation();

  return token ? (
    <Navigate
      to={AUCTIONEER_PATHS.DASHBOARD}
      state={{ from: location }}
      replace
    />
  ) : (
    <Outlet />
  );
};

export default AuthNotRequired;
