import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "@/bidda/api/axiosBaseQuery";
import config from "@/bidda/config/config";

export const bidApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/api/v1/bid` }),
  endpoints: () => ({}),
  reducerPath: "bid",
  keepUnusedDataFor: 60,
  tagTypes: ["AUCTIONS", "BID"],
});
