import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@/bidda/theme/colors";

export const useCompanyDetailsStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: colors.primary[500],
    fontWeight: "bold",
    textTransform: "none",
  },
  body: {
    padding: theme.spacing(2),
  },
  header: {
    color: colors.black,
    fontWeight: 600,
    m: 2,
  },
  uploadButton: {
    border: `1px solid ${colors.grey[200]}`,
    color: colors.grey[600],
  },
  textButton: {
    textTransform: "none",
  },
  uploadContainer: {
    border: `1px solid ${colors.grey[200]}`,
    padding: theme.spacing(2),
    textAlign: "center",
    borderRadius: "12px",
    backgroundColor: colors.white,
  },
  companyLogo: {
    display: "flex",
    justifyContent: "space-between",
  },
  logobox: {
    paddingRight: "30px",
  },
}));
