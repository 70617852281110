
export interface ISettingsPasswordDetails {
    currentPassword: string;
    newPassword: string;
    setNewPassword: string;
  };

export const SETTINGS_PASSWORD_DETAILS: ISettingsPasswordDetails = {
    currentPassword: "",
    newPassword: "",
    setNewPassword: "",
  };

export interface ISettingsCompanyDetails {
  fullName: string,
  phoneNumber: string,
  companyName: string,
  companyEmail: string,
  companyPhone: string,
  industry: string,
  size: string,
  country: string,
  companyAddress: string,
  companyLogo: string

}

export const SETTINGS_COMPANY_DETAILS: ISettingsCompanyDetails = {
  fullName: "",
  phoneNumber: "",
  companyName: "",
  companyEmail: "",
  companyPhone: "",
  industry: "",
  size: "",
  country: "",
  companyAddress: "",
  companyLogo: ""
}