import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Divider, Grid, Radio, Typography } from "@material-ui/core";

import cn from "classnames";
import { ReactComponent as SwitchSvg } from "@/bidda/_shared/assets/icons/switch-account-icon.svg";
import { useSwitchAccountModalStyles } from "./style";
import useLogOut from "@/bidda/_shared/hooks/useLogOut";
import { useCurrentAccount } from "@/bidda/_shared/hooks/useCurrentAccount";
import { Button } from "@mui/material";

type SwitchAccountModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
};

const LogoutModal: FC<SwitchAccountModalProps> = ({ isModalOpen, onClose }) => {
  const { logOut } = useLogOut();
  const { removeCurrentAccount } = useCurrentAccount();

  const modalStyles = useSwitchAccountModalStyles();

  return (
    <Box>
      <Modal
        open={isModalOpen}
        onClose={onClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className={cn(modalStyles.auctionFeeModal)}
          sx={{ width: { xs: "70%", md: "40%" } }}
        >
          <Grid container item className={cn(modalStyles.modalBody)}>
            <Box component={"span"}>
              {/* <Box className={modalStyles.switchIconBox}>
                <SwitchSvg className={modalStyles.switchIcon} />
              </Box> */}
              <Box className={modalStyles.cardBody}>
                <Typography
                  variant={"h4"}
                  className={modalStyles.modalHeaderText}
                >
                  Confirm logout
                </Typography>
                <Typography variant={"body2"}>
                  Are you sure you want to log out of your account? Confirm to
                  proceed.
                </Typography>
              </Box>
            </Box>

            <Grid item container>
              <Grid item md={6} className={modalStyles.btnHL}>
                <Button
                  size="large"
                  style={{ fontSize: "14px !important", width: "100%" }}
                  onClick={onClose}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item md={6} className={modalStyles.btnHR}>
                <Button
                  onClick={() => {
                    logOut();
                    removeCurrentAccount();
                  }}
                  size="large"
                  style={{ fontSize: "14px !important", width: "100%" }}
                  variant="contained"
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default LogoutModal;
