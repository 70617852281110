import AppRouter from "@/router";
import "./styles/index.css";
import "react-phone-number-input/style.css";

const App = () => {
  return (
    <div>
      <AppRouter />
    </div>
  );
};

export default App;
