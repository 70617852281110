import { Yup } from "@/bidda/_lib/yup";
import { AnySchema } from "yup";

export const companyDetailsValidationSchema = (): AnySchema => {
  return Yup.object({
    fullName: Yup.string().required("This field is required"),
    phoneNumber: Yup.string().required("This field is required"),
    companyName: Yup.string().required("This field is required"),
    companyEmail: Yup.string()
      .nullable()
      .trim()
      .extEmail("Invalid email")
      .required("This field is required"),
    companyPhone: Yup.string().required("This field is required"),
    industry: Yup.string()
      .trim()
      .required("Industry is required")
      .max(256, "Too many symbols"),
    size: Yup.string().required("This field is required"),
    country: Yup.string()
      .trim()
      .required("Country is required")
      .max(256, "Too many symbols"),
    companyAddress: Yup.string()
      .trim()
      .required("Address is required")
      .max(256, "Too many symbols"),
    companyLogo: Yup.string().required("This field is required"),
  });
};
