import { AuctioneerInvite } from "@/bidda/types/auctioneer";

export interface IPersonalDetailsFormInitialValue {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  password: string;
}

export interface IAuthLoginFormInitialValue {
  email: string;
  password: string;
}

export const PERSONAL_DETAILS: IPersonalDetailsFormInitialValue = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
};

export const LOGIN_DETAILS: IAuthLoginFormInitialValue = {
  email: "",
  password: "",
};

export interface ICompanyProfileFormInitialValue {
  companyName: string;
  companyEmail: string;
  companyPhone: string;
  logo?: string | null;
}

export const COMPANY_PROFILE: ICompanyProfileFormInitialValue = {
  companyName: "",
  companyEmail: "",
  companyPhone: "",
  logo: "",
};

export interface ICompanyInformationFormInitialValue {
  industry: string;
  state: string;
  country: string;
  address?: string | null;
  size?: string | null;
}

export const COMPANY_INFORMATION: ICompanyInformationFormInitialValue = {
  industry: "",
  state: "",
  country: "",
  address: "",
};

export interface ICompanyBankDetailsFormInitialValue {
  bankName: string;
  accountName: string;
  accountNumber: string;
}

export const COMPANY_BANK_DETAILS: ICompanyBankDetailsFormInitialValue = {
  bankName: "",
  accountName: "",
  accountNumber: "",
};

export interface ICompanyInvitationPayload {
  invitation?: AuctioneerInvite;
  personalDetails?: IPersonalDetailsFormInitialValue;
  companyProfile?: ICompanyProfileFormInitialValue;
  companyInformation?: ICompanyInformationFormInitialValue;
  companyBankDetails?: ICompanyBankDetailsFormInitialValue;
}

export interface ICompanyBankDetailsAPIFormat {
  bankName?: string;
  accountNumber?: string;
  accountName?: string;
}

export interface ICompanyAPIFormat {
  name?: string;
  email?: string;
  address?: string;
  phone?: string;
  country_id?: number;
  logo?: string | null;
  size?: string;
  industry?: string;
}

export interface IUserAPIFormat {
  first_name?: string;
  last_name?: string;
  email?: string | null;
  password?: string;
  username?: string | null;
  gender?: string | null;
  phone?: string;
  country_id?: number | null;
}

export interface IPayloadToAPIFormat {
  code: string;
  user: IUserAPIFormat;
}

export interface IPasswordResetPayload {
  email: string | null;
  otp: number | null;
  password: string | null;
}

export const PASSWORD_RESET_DETAILS: IPasswordResetPayload = {
  email: "",
  otp: null,
  password: "",
};

export enum PasswordResetStages {
  enterEmail = "enter-email",
  enterOtp = "enter-otp",
  enterPassword = "enter-password",
}
