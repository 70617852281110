type UseIsAuctioneerReturnType = {
  isAuctioneer: boolean;
  auctioneer: any;
};

export const useIsAuctioneer = (): UseIsAuctioneerReturnType => {
  const storage = localStorage.getItem("authState");
  const { user } = !!storage ? JSON.parse(storage) : false;
  const truthy = !!user.auctioneer;
  return { isAuctioneer: truthy, auctioneer: user.auctioneer };
};