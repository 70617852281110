import React, { useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import cn from "classnames";
import { Button } from "@mui/material";
import { ReactComponent as CarIcon } from "@/marketplace/assets/icons/car-icon.svg";
import { ReactComponent as LaptopIcon } from "@/marketplace/assets/icons/laptop-icon.svg";
import { ReactComponent as LayerIcon } from "@/marketplace/assets/icons/layers-icon.svg";
import { ReactComponent as PrinterIcon } from "@/marketplace/assets/icons/printer-icon.svg";
import { ReactComponent as KeyIcon } from "@/marketplace/assets/icons/key-icon.svg";

interface Props {
  onUpdateStage: () => void;
  onCancel: () => void;
}

const Preference: React.FC<Props> = ({ onUpdateStage, onCancel }) => {
  const [selectedPreferences, setSelectedPreferences] = useState<string[]>([]);

  const preferences = [
    {
      key: "computer",
      icon: (isSelected: boolean) => (
        <LaptopIcon
          className={cn({
            "stroke-white": isSelected,
          })}
        />
      ),
      name: "Computer & Phones",
    },
    {
      key: "vehicle",
      icon: (isSelected: boolean) => (
        <CarIcon
          className={cn({
            "stroke-white": isSelected,
          })}
        />
      ),
      name: "Vehicle & Automobile",
    },
    {
      key: "furniture",
      icon: (isSelected: boolean) => (
        <KeyIcon
          className={cn({
            "stroke-white": isSelected,
          })}
        />
      ),
      name: "Furniture & fitting",
    },
    {
      key: "electronics",
      icon: (isSelected: boolean) => (
        <PrinterIcon
          className={cn({
            "stroke-white": isSelected,
          })}
        />
      ),
      name: "Electronics",
    },
    {
      key: "others",
      icon: (isSelected: boolean) => (
        <LayerIcon
          className={cn({
            "stroke-white": isSelected,
          })}
        />
      ),
      name: "Others",
    },
  ];

  const onSelectPreference = (key: string) => {
    if (selectedPreferences.includes(key)) {
      setSelectedPreferences((prevSelected) =>
        prevSelected.filter((prevKey) => prevKey !== key)
      );
    } else {
      setSelectedPreferences((prev) => [...prev, key]);
    }
  };

  return (
    <div className="relative bg-gray-50 pb-20">
      <div className="px-4 pt-5">
        <IoChevronBackOutline
          className="text-xl cursor-pointer"
          onClick={onCancel}
        />
      </div>

      <div className="pb-4 pt-8 px-4">
        <h2 className="font-medium text-2xl">Choose your preference</h2>
        <span className="text-gray-700 text-lg">
          Let’s help personalize your feed, please pick at least 3 items of
          interest.
        </span>
      </div>

      <div className="p-10 flex flex-wrap gap-y-8 justify-between">
        {preferences.map((preference) => (
          <div
            className="text-center"
            key={preference.key}
            onClick={() => onSelectPreference(preference.key)}
          >
            <div
              className={cn(
                "h-[130px] transition-all duration-300 mb-2 p-10 w-[130px] border grid place-content-center rounded-full shadow-sm",
                {
                  "bg-blue-700": selectedPreferences.includes(preference.key),
                  "bg-white": !selectedPreferences.includes(preference.key),
                }
              )}
            >
              {preference.icon(selectedPreferences.includes(preference.key))}
            </div>
            <span className="text-sm text-gray-900">{preference.name}</span>
          </div>
        ))}
      </div>

      <div className="fixed bottom-0 px-4 w-full py-3 bg-white shadow-lg">
        <Button variant="contained" className="w-full" onClick={onUpdateStage}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default Preference;
