const AUCTIONEE_PATHS = {
  DASHBOARD: "/auctionee/dashboard",
  RESULT_AUCTION: "/auctionee/auction/result/:id",
  ALL_AUCTIONS: "/auctionee/all-auctions/",
  VIEW_AUCTION: "/auctionee/auction/:id",

  REGISTER: "/auctionee/invite/:id",
};

export default AUCTIONEE_PATHS;
