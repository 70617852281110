import { useState } from "react";
import Box from "@mui/material/Box";
import LogoutModal from "../../Modal/LogoutModal";
import DrawerContent from "./Drawer/AuctioneerDrawer/DrawerContent";
import DesktopDrawer from "./Drawer/AuctioneerDrawer/DesktopDrawer";
import SwitchAccountModal from "../../Modal/SwitchAccountModal";
import MobileDrawer from "./Drawer/AuctioneerDrawer/MobileDrawer";
import { useAuthUserDetail } from "@/bidda/_shared/hooks/useAuthUserDetail";

const AuctioneerSideMenu = () => {
  const [desktopOpen, setIsDesktopOpen] = useState(false);
  const [mobileOpen, setIsMobileOpen] = useState(false);

  const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] =
    useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const authUser = useAuthUserDetail();
  const { getUserDetails } = authUser;
  const { user } = getUserDetails();

  const toggleDrawer = () => {
    setIsDesktopOpen(!desktopOpen);
  };

  const handleSwitchAccount = () => {
    setIsSwitchAccountModalOpen(true);
  };

  const handleShowLogOut = () => {
    setIsLogoutModalOpen(true);
  };

  return (
    <Box>
      <DesktopDrawer isOpen={desktopOpen} handleDrawerClose={toggleDrawer}>
        <DrawerContent
          isDesktopOpen={desktopOpen}
          handleDrawerOpen={toggleDrawer}
          handleShowLogOut={handleShowLogOut}
          handleSwitchAccount={handleSwitchAccount}
        />
      </DesktopDrawer>

      <MobileDrawer
        mobileOpen={mobileOpen}
        onMobileOpen={() => setIsMobileOpen(!mobileOpen)}
      >
        <DrawerContent
          isMobileOpen={mobileOpen}
          handleDrawerOpen={toggleDrawer}
          handleShowLogOut={handleShowLogOut}
          handleSwitchAccount={handleSwitchAccount}
        />
      </MobileDrawer>

      <SwitchAccountModal
        isModalOpen={isSwitchAccountModalOpen}
        onClose={() => setIsSwitchAccountModalOpen(false)}
        authUser={authUser}
      />
      <LogoutModal
        isModalOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
      />
    </Box>
  );
};

export default AuctioneerSideMenu;
