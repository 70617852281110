import { AuctioneeLayout } from "@/bidda/_shared/components/layout/Auctionee";
import { AuctioneerLayout } from "@/bidda/_shared/components/layout/Auctioneer";
import { useCurrentAccount } from "@/bidda/_shared/hooks/useCurrentAccount";
import AUCTIONEE_PATHS from "@/bidda/routerPaths/auctionee";
import AUCTIONEER_PATHS from "@/bidda/routerPaths/auctioneer";
import { AUTH_PATHS } from "@/bidda/routerPaths/auth";
import { FC, useEffect } from "react";
import { useLocation, Outlet, Navigate, useNavigate } from "react-router-dom";

const AuthRequired: FC = () => {
  const storage = localStorage?.getItem("authState");
  const navigate = useNavigate();

  const { token } = !!storage ? JSON.parse(storage) : "";

  const { getCurrentAccount } = useCurrentAccount();
  const currentAccount = getCurrentAccount();

  const location = useLocation();
  const userTypeUrl = location.pathname.split("/")[1];

  useEffect(() => {
    if (userTypeUrl === "auctioneer" && currentAccount === "auctionee") {
      navigate(AUCTIONEE_PATHS.DASHBOARD);
    }
    if (userTypeUrl === "auctionee" && currentAccount === "auctioneer") {
      navigate(AUCTIONEER_PATHS.DASHBOARD);
    }
  }, [currentAccount, userTypeUrl]);

  return token ? (
    <>
      {currentAccount === "auctionee" && (
        <AuctioneeLayout>
          <Outlet />
        </AuctioneeLayout>
      )}
      {currentAccount === "auctioneer" && (
        <AuctioneerLayout>
          <Outlet />
        </AuctioneerLayout>
      )}
    </>
  ) : (
    <Navigate to={AUTH_PATHS.LOGIN} state={{ from: location }} replace />
  );
};

export default AuthRequired;
