import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface IGlobalInitialState {
  darkTheme: boolean;
  payload: any | null;
}

const initialState: IGlobalInitialState = {
  darkTheme: false,
  payload: null,
};

export const slice = createSlice({
  name: "global",
  initialState,
  reducers: {
    toggleTheme: (state, { payload }) => {
      state.darkTheme = payload !== undefined ? !!payload : !state.darkTheme;
    },
    setPayload: (state, action) => {
      const { payload, reset } = action.payload;
      state.payload = reset ? null : state.payload ;
      state.payload = payload !== null ? payload : {}
    }
  },
});

export const { toggleTheme, setPayload } = slice.actions;

export const global = slice.reducer;

export const getPayload = (state: RootState) => state.global.payload;
