import React from "react";
import ReactDOM from "react-dom/client";
import "../src/bidda/index.css";
import BiddaApp from "../src/bidda/App";
import MarketplaceApp from "../src/marketplace/App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import RootApp from "./RootApp";

const { REACT_APP_ENV, REACT_APP_SENTRY_DSN } = process.env;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Sentry should be initialized as early as possible in application lifecycle
Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: REACT_APP_ENV,
});

const isMarketPlace = window.location.pathname.includes("marketplace");

if (isMarketPlace) {
  root.render(
    <React.StrictMode>
      <RootApp>
        <MarketplaceApp />
      </RootApp>
    </React.StrictMode>
  );
}

root.render(
  <React.StrictMode>
    <RootApp>
      <BiddaApp />
    </RootApp>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
