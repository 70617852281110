import React, { useState } from "react";
import OtpModal from "./OtpModal";
import SignUp from "./SignUp";
import ProfileUpdate from "./ProfileUpdate";
import Region from "./Region";
import Preference from "./Preference";
import LoadingModal from "@/marketplace/components/LoadingModal";

export enum StageFlow {
  SignUp,
  ProfileInfo,
  AddRegion,
  ItemPreference,
}

const BuyerSignup = () => {
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [stage, setStage] = useState(StageFlow.SignUp);

  return (
    <>
      {stage === StageFlow.SignUp && (
        <SignUp setIsOtpModalOpen={() => setIsOtpModalOpen(true)} />
      )}

      {stage === StageFlow.ProfileInfo && (
        <ProfileUpdate
          onUpdateStage={() => setStage(StageFlow.AddRegion)}
          onCancel={() => setStage(StageFlow.SignUp)}
        />
      )}

      {stage === StageFlow.AddRegion && (
        <Region
          onUpdateStage={() => setStage(StageFlow.ItemPreference)}
          onCancel={() => setStage(StageFlow.ProfileInfo)}
        />
      )}

      {stage === StageFlow.ItemPreference && (
        <Preference
          onUpdateStage={() => setIsSignInModalOpen(true)}
          onCancel={() => setStage(StageFlow.AddRegion)}
        />
      )}

      <LoadingModal isModalOpen={isSignInModalOpen} onModalClose={() => null} />

      <OtpModal
        onModalClose={() => setIsOtpModalOpen(false)}
        isModalOpen={isOtpModalOpen}
        onUpdateStage={() => {
          setStage(StageFlow.ProfileInfo);
          setIsOtpModalOpen(false);
        }}
      />
    </>
  );
};

export default BuyerSignup;
