import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "@/bidda/api/axiosBaseQuery";
import config from "@/bidda/config/config";

export const authApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/api/v1/auth` }),
  endpoints: () => ({}),
  reducerPath: "auth",
  keepUnusedDataFor: 30,
  tagTypes: [],
});
