import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./bidda/store/store";
import { SnackbarProvider } from "notistack";
import { HandleResponseErrors } from "./bidda/api/HandleResponseErrors";
import { ToastContainer } from "react-toastify";

import "dayjs/locale/en-gb";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-tagsinput/react-tagsinput.css";

const RootApp: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider
            maxSnack={2}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            autoHideDuration={3000}
            preventDuplicate
          >
            <HandleResponseErrors />
            <ToastContainer />
            {children}
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default RootApp;
