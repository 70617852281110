import React, { useState } from "react";
import SignUp from "./SignUp";
import ProfileUpdate from "./ProfileUpdate";
import Address from "./Address";
import Preference from "./Preference";
import LoadingModal from "@/marketplace/components/LoadingModal";
import OtpModal from "./OtpModal";
import BankDetails from "./BankDetails";

export enum StageFlow {
  SignUp,
  ProfileInfo,
  AddAddress,
  BankDetails,
}

const SellerSignup = () => {
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [stage, setStage] = useState(StageFlow.SignUp);

  return (
    <>
      {stage === StageFlow.SignUp && (
        <SignUp setIsOtpModalOpen={() => setIsOtpModalOpen(true)} />
      )}

      {stage === StageFlow.ProfileInfo && (
        <ProfileUpdate
          onUpdateStage={() => setStage(StageFlow.AddAddress)}
          onCancel={() => setStage(StageFlow.SignUp)}
        />
      )}

      {stage === StageFlow.AddAddress && (
        <Address
          onUpdateStage={() => setStage(StageFlow.BankDetails)}
          onCancel={() => setStage(StageFlow.ProfileInfo)}
        />
      )}

      {stage === StageFlow.BankDetails && (
        <BankDetails
          onUpdateStage={() => setIsSignInModalOpen(true)}
          onCancel={() => setStage(StageFlow.AddAddress)}
        />
      )}

      <LoadingModal isModalOpen={isSignInModalOpen} onModalClose={() => null} />

      <OtpModal
        onModalClose={() => setIsOtpModalOpen(false)}
        isModalOpen={isOtpModalOpen}
        onUpdateStage={() => {
          setStage(StageFlow.ProfileInfo);
          setIsOtpModalOpen(false);
        }}
      />
    </>
  );
};

export default SellerSignup;
