import { colors } from "@/bidda/theme/colors";
import { fontSizes } from "@/bidda/theme/fonts";
import { makeStyles } from "@material-ui/core";

export const useSwitchAccountModalStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    background: "#ffffff !important",
    border: "2px solid #ffffff",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    borderRadius: "12px",
    "& :focus-visible": {
      border: "none",
    },
  },
  auctionFeeModal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    background: "#ffffff !important",
    border: "2px solid #ffffff",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    borderRadius: "12px",
    "& :focus-visible": {
      border: "none",
    },
  },
  modalHeader: {
    padding: "15px 30px",
  },
  modalBody: {
    padding: "30px",
  },
  divider: {
    backgroundColor: "#EAECF0",
  },
  noPadding: {
    paddingBottom: "0px",
  },
  paddingTop: {
    paddingTop: "20px",
  },
  primaryBtn: {
    background: "#004CCC !important",
    color: "#fff !important",
    width: "100%",
    borderRadius: "8px !important",
    border: "1px solid #004CCC !important",
    fontWeight: 600,
    fontSize: `${fontSizes.small[40]} !important`,
  },
  transparentBtn: {
    background: "transparent",
    color: `${colors.grey[700]} !important`,
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    width: "100%",
    border: "1px solid #D0D5DD !important",
    borderRadius: "8px !important",
    fontWeight: 600,
    fontSize: `${fontSizes.small[40]} !important`,
    "&:hover": {
      background: "transparent",
    },
  },
  btnHL: {
    paddingRight: "5px",
  },
  btnHR: {
    paddingLeft: "5px",
  },
  iconGrid: {
    border: "1px solid #EAECF0",
    textAlign: "center",
    borderRadius: "8px !important",
  },
  assetIcon: {
    position: "relative",
    top: "15px",
  },
  headerGrid: {
    paddingLeft: "15px !important",
  },
  cardImage: {
    width: "100%",
  },
  cardBody: {
    margin: "15px 0px",
  },
  modalHeaderText: {
    fontWeight: 600,
    marginBottom: "5px",
  },
  blueText: {
    color: colors.primary[700],
    fontWeight: 400,
  },
  radioBox: {
    border: `1px solid ${colors.grey[200]}`,
    borderRadius: "12px",
    padding: "15px 12px",
    margin: "10px 0px",
  },
  account: {
    fontWeight: 500,
    marginBottom: "-5px",
  },
  active: {
    background: colors.primary[5],
    border: `2px solid ${colors.primary[500]}`,
  },
  switchIconBox: {
    border: `1px solid ${colors.grey[200]}`,
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05) !important",
    borderRadius: "10px",
    padding: "14px 15px 10px 15px",
  },
  switchIcon: {},
}));
