import React from "react";
import { Outlet } from "react-router-dom";

const RootLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="text-gray-900">
      <Outlet />
      {children}
    </div>
  );
};

export default RootLayout;
