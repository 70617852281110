import { ExtendedRoute } from "@/bidda/_lib/routing/types";
import AuthRequired from "@/bidda/pages/auth/component/AuthRequired";
import DashboardContainer from "../container/Dashboard";
import MARKETPLACE_PATHS from "./marketplace";
import { Outlet } from "react-router-dom";
import Signup from "../pages/signup";
import RootLayout from "../container/RootLayout";

const routes: ExtendedRoute[] = [
  {
    path: "/marketplace",
    exact: true,
    private: true,
    element: <RootLayout />,
    // element: <AuthRequired />,
    children: [
      {
        path: MARKETPLACE_PATHS.HOME,
        element: <Signup />,
        // element: <DashboardContainer />,
      },
      {
        path: MARKETPLACE_PATHS.SELLER_HOME,
        element: <Signup />
      }
    ],
  },
];

const marketplaceRoutes: ExtendedRoute[] = [...routes];

export default marketplaceRoutes;
