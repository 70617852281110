import { Box } from "@mui/material";
// import NProgress from "nprogress";
import React, { FC, useState, useEffect, useRef } from "react";
import LoadingBar from 'react-top-loading-bar'

const LoadingScreen: FC = () => {

  const [progress, setProgress] = useState(10)
  const ref = useRef(null);

  useEffect(() => {

    return () => {
      setProgress(100);
    };
  }, []);

  return <LoadingBar color='#f11946' ref={ref} />;
};

export default LoadingScreen;
