import * as React from "react";
import { FC } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  IconButton,
  Button,
  FormHelperText,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import { FormLabel } from "@/bidda/_lib/mui/forms/FormLabel";
import { useGetCountriesQuery } from "@/bidda/api/baseQueries/country/getCountries";
import { Select } from "@/bidda/_lib/formik/components/Select";
import { Formik } from "formik";
import { Form } from "@/bidda/_lib/formik/components/Form";
import { TextField } from "@/bidda/_lib/formik/components/TextField";
import { PhoneField } from "@/bidda/_lib/formik/components/PhoneField";
import MuiPhoneNumber from "material-ui-phone-number";
import { SelectOptions } from "@/bidda/_lib/mui/forms/SingleSelect";
import { transformToSelectOptionList } from "@/bidda/_shared/utils/functions/transformToSelectOptionList";
import { SETTINGS_COMPANY_DETAILS } from "../../types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { transformCountriesToSelectOptionList } from "@/bidda/_shared/utils/functions/transformCountriesToSelectOptionList";
import { ReactComponent as businessSvg } from "@/bidda/_shared/assets/icons/business-icon.svg";
import { ReactComponent as UploadIcon } from "@/bidda/_shared/assets/icons/upload-icon.svg";
import { ReactComponent as AuctionLogo } from "@/bidda/_shared/assets/icons/auction-icon.svg";
import { ReactComponent as LogoMark } from "@/bidda/_shared/assets/icons/Logomark.svg";
import { companyDetailsValidationSchema } from "./companyDetailsValidationSchema";
import { useCompanyDetailsStyles } from "../../styles/companyDetailsStyles";
import { industries, teamSize } from "@/bidda/pages/auth/constants";

const CompanyProfile: FC = () => {
  const classes = useCompanyDetailsStyles();

  const handleChange = () => {};

  const onSubmit = () => {};

  const {
    data: countries,
    isFetching: isFetchingCountries,
    isSuccess: isSuccessCountries,
  } = useGetCountriesQuery();

  const industrySelectOption: SelectOptions =
    transformToSelectOptionList(industries);

  const teamSizeSelectpOtion: SelectOptions =
    transformToSelectOptionList(teamSize);

  const countriesSelectOption: SelectOptions =
    !isFetchingCountries && isSuccessCountries && countries
      ? transformCountriesToSelectOptionList(countries.data)
      : transformCountriesToSelectOptionList([]);

  const validationSchema = companyDetailsValidationSchema();

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        md={12}
        className={classes.body}
      >
        <Grid item>
          <Typography className={classes.header}>Company profile</Typography>
          <FormHelperText>
            Update your company photo and details here.
          </FormHelperText>
        </Grid>
        <Grid item>
          <Button variant="contained" className={classes.button}>
            Save
          </Button>
        </Grid>
      </Grid>

      <Formik
        initialValues={SETTINGS_COMPANY_DETAILS}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Full Name</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Stack direction="row" spacing={2}>
                <TextField
                  size="small"
                  type={"text"}
                  name={"fullName"}
                  id={"fullName"}
                  placeholder={"Uche"}
                />
                <TextField
                  size="small"
                  type={"text"}
                  name={"fullName"}
                  id={"fullName"}
                  placeholder={"Ngozi"}
                />
              </Stack>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Phone Number</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              {/* <PhoneField 
                        name={"phoneNumber"}
                        label={"09074989145"}    
                        // variant="outlined"   
                    /> */}
              <MuiPhoneNumber
                name={"phoneNumber"}
                onChange={handleChange}
                variant="outlined"
                defaultCountry={"ng"}
                placeholder={"Enter phone number"}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Company Name</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                type={"text"}
                id={"companyName"}
                name={"companyName"}
                placeholder={"Singletechno"}
                fullWidth
              />
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Company Email</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                type={"email"}
                id={"companyEmail"}
                name={"companyEmail"}
                placeholder={"info.singletechno.com"}
                fullWidth
              />
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Company Phone Number</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <MuiPhoneNumber
                name={"phoneNumber"}
                onChange={handleChange}
                variant="outlined"
                defaultCountry={"ng"}
                placeholder={"Enter phone number"}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Industry</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Select
                data={industrySelectOption}
                type={"text"}
                name={"industry"}
                menuTitle="Select your industry"
                placeholder={"Select your industry"}
                fullWidth
              />
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Size</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Select
                data={teamSizeSelectpOtion}
                type={"text"}
                name={"teamSize"}
                menuTitle="Select your team size"
                fullWidth
              />
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Select Country</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Select
                data={countriesSelectOption}
                type={"text"}
                id={"country"}
                name={"country"}
                defaultValue={"ng"}
                menuTitle="Select country"
                fullWidth
              />
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Company Address</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                type={"text"}
                id={"companyAddress"}
                name={"companyAddress"}
                placeholder={"Number 4 joseph ali street, ikoyi, Lagos State."}
                fullWidth
              />
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container md={12} className={classes.body}>
            <Grid item xs={4}>
              <FormLabel>Company Logo</FormLabel>
              <FormHelperText>
                This will be displayed on your profile.
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              {/* <businessSvg/> */}
              {/* <TextField
                        type={"file"}
                        id={"companyLogo"}
                        name={"companyLogo"}
                        label="Upload Image"
                        fullWidth
                    >
                   </TextField> */}
              <Box className={classes.companyLogo}>
                <Box className={classes.logobox}>
                  {" "}
                  <LogoMark />{" "}
                </Box>
                <Box className={classes.uploadContainer}>
                  <IconButton aria-label="upload picture" component="label">
                    <input hidden accept="image/*" type="file" />
                    <UploadIcon />
                  </IconButton>
                  <Typography className={classes.textButton}>
                    <Button variant="text">Click to upload </Button>
                    or drag and drop SVG, PNG, JPG or GIF (max. 800x400px)
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
export default CompanyProfile;
