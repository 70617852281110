import { Button, Modal } from "@mui/material";
import React from "react";
import { HiOutlineEnvelope } from "react-icons/hi2";
import AuthCode from "react-auth-code-input";
import { ReactComponent as FeaturedIcon } from "@/marketplace/assets/icons/featured-icon.svg";
import { ReactComponent as CloseIcon } from "@/marketplace/assets/icons/close-icon.svg";

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
  onUpdateStage: () => void;
}

const OtpModal: React.FC<Props> = ({
  isModalOpen,
  onModalClose,
  onUpdateStage,
}) => {
  return (
    <div>
      <Modal open={isModalOpen} onClose={onModalClose}>
        <div
          className="absolute left-1/2 transform bottom-10 -translate-x-1/2 
         w-[328px] bg-white shadow-md p-6 rounded-xl"
        >
          <div className="relative">
            <div className="absolute -right-3 -top-3">
              <CloseIcon
                className="hover:cursor-pointer"
                onClick={onModalClose}
              />
            </div>
            <div className="grid place-content-center pb-3">
              <FeaturedIcon />
            </div>
            <div className="flex flex-col justify-center items-center pb-5">
              <span className="text-lg font-semibold text-gray-900">
                Please check your email.
              </span>
              <span className="text-sm text-gray-600 font-normal">
                We've sent a code to{" "}
                <span className="font-semibold">odiri@gmail.com</span>
              </span>
            </div>

            <div className="w-full pb-1">
              <AuthCode
                onChange={(value) => console.log(value)}
                length={4}
                containerClassName="flex gap-x-2 w-full justify-between"
                inputClassName="w-[64px] font-ibm h-[64px] border text-center text-5xl text-blue-600 p-2"
                allowedCharacters="numeric"
              />
            </div>
            <span className="text-gray-600 text-sm block pb-6">
              Didn’t get a code?{" "}
              <span className="underline">Click to resend.</span>
            </span>

            <div className="flex flex-col gap-y-3">
              <Button variant="contained" onClick={onUpdateStage}>
                Verify
              </Button>
              <Button variant="outlined" onClick={onModalClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OtpModal;
