import { createTheme } from "@material-ui/core/styles";

import { fontSizes } from "./fonts";
import { colors } from "./colors";

import {
  IBMLight,
  IBMRegular,
  IBMMedium,
  IBMSemiBold,
  IBMBold,
  IBMLightItalic,
  IBMRegularItalic,
  IBMMediumItalic,
  IBMSemiBoldItalic,
  IBMBoldItalic,
} from "./fonts";

export const customTheme = createTheme({
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      light: colors.primary[300],
      main: colors.primary[500],
      dark: colors.primary[700],
    },
    secondary: {
      light: colors.secondary[300],
      main: colors.secondary[500],
      dark: colors.secondary[700],
    },
    error: {
      light: colors.error[300],
      main: colors.error[500],
      dark: colors.error[700],
    },
    text: {
      primary: colors.grey[600],
      // secondary: '#637381',
      // disabled: '#919EAB',
    },
  },

  typography: {
    fontFamily: '"IBM Plex Sans", "Public Sans", sans-serif',
    h1: {
      fontSize: fontSizes.large[30],
      lineHeight: "72px",
      fontWeight: 600,
      color: colors.grey[900],
      textTransform: "capitalize",
    },
    h2: {
      fontSize: fontSizes.middle[30],
      lineHeight: "38px",
      fontWeight: 600,
      color: colors.grey[900],
    },
    h3: {
      fontSize: fontSizes.middle[20],
      lineHeight: "32px",
      fontWeight: 600,
      color: colors.grey[900],
    },
    h4: {
      fontSize: fontSizes.small[50],
      lineHeight: "28px",
      fontWeight: 500,
      color: colors.grey[900],
    },
    h5: {
      fontSize: fontSizes.small[40],
      lineHeight: "24px",
      fontWeight: 400,
      color: colors.grey[900],
    },
    h6: {
      fontSize: fontSizes.small[20],
      lineHeight: "24px",
      fontWeight: 400,
      color: colors.grey[900],
    },
    body1: {
      fontSize: fontSizes.small[40],
      lineHeight: "24px",
      fontWeight: 400,
    },
    body2: {
      fontSize: fontSizes.small[20],
      lineHeight: "24px",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: fontSizes.small[40],
      lineHeight: "24px",
      fontWeight: 400,
      color: colors.grey[600],
      marginTop: "12px",
      marginBottom: "32px",
    },
    subtitle2: {
      fontSize: fontSizes.small[20],
      lineHeight: "24px",
      fontWeight: 400,
    },
  },
  zIndex: { modal: 50000 },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          IBMLight,
          IBMRegular,
          IBMMedium,
          IBMSemiBold,
          IBMBold,
          IBMLightItalic,
          IBMRegularItalic,
          IBMMediumItalic,
          IBMSemiBoldItalic,
          IBMBoldItalic,
        ],
      },
    },

    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "10px 30px",
        borderBottom: `1px solid ${colors.grey[200]}`,
      },
    },

    MuiFormLabel: {
      root: {
        "&.Mui-disabled": {
          color: `${colors.grey[400]} !important`,
        },
      },
    },

    MuiInputBase: {
      root: {
        "& fieldset": {
          border: `1px solid ${colors.grey[300]}`,
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        },

        "&.Mui-disabled": {
          color: `${colors.grey[400]} !important`,
        },

        "&.Mui-disabled fieldset": {
          borderColor: `${colors.grey[200]} !important`,
          color: `${colors.grey[400]} !important`,
        },
      },
    },

    MuiButton: {
      root: {
        borderRadius: "8px",
        textTransform: "capitalize",
        fontSize: `${fontSizes.small[40]}`,
        "&.Mui-disabled": {
          border: "1px solid #B0CDFF",
          background: "#B0CDFF !important",
          color: `${colors.white} !important`,
          // border: "1px solid #D0D5DD !important",
          // background: "#D0D5DD !important",
          // color: `${colors.white} !important`,
        },
      },
    },

    MuiCheckbox: {
      root: {
        "& input": {
          // border: `1px solid ${colors.primary[500]} !important`,
          borderRadius: "8px !important",
        },
        "&.Mui-checked": {
          color: `${colors.primary[500]} !important`,
        },
      },
    },

    MuiFormHelperText: {
      root: {
        display: "none",
      },
    },

    MuiIconButton: {
      root: {
        "&.Mui-disabled": {
          color: `${colors.grey[400]} !important`,
        },
      },
    },
  },

  props: {
    MuiTextField: {
      variant: "outlined",
      color: "secondary",
    },
    MuiButton: {
      disableElevation: true,
      variant: "contained",
      color: "primary",
    },
    MuiButtonGroup: {
      disableElevation: true,
      variant: "contained",
      color: "primary",
    },
    MuiIconButton: {
      color: "primary",
    },
    MuiSelect: {
      color: "secondary",
    },
    MuiFormControl: {
      variant: "outlined",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
});
