import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { handleRTKQFormErrors } from "./handleBackendFormErrors";

import { AxiosResponse } from "axios";
import { IErrorResponse } from "@/bidda/_shared/types";

interface IProps {
  error: unknown;
}

export const FormErrorsHandler: React.FC<IProps> = ({ error }) => {
  const { setErrors } = useFormikContext();

  useEffect(() => {
    if (error) {
      handleRTKQFormErrors(error as AxiosResponse<IErrorResponse>, setErrors);
    }
  }, [error, setErrors]);

  return null;
};
