export interface ISelectOptionObject {
  name: string;
  value: string | number;
  // states?: []
}

export const transformCountriesToSelectOptionList = (array: any) => {
  let formattedList: ISelectOptionObject[] = [];

  array.map((item: any) => {    
    const list = {
      name: item?.name,
      value: item?.id,
      // states: item?.states
    };
    formattedList = [...formattedList, list];
  });

  return formattedList;
};