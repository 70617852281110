const AUCTIONEER_PATHS = {
  DASHBOARD: "/auctioneer/dashboard",
  AUCTIONS: "/auctioneer/auctions",
  PREVIEW_AUCTION: "/auctioneer/auction/preview/:id",

  CREATE_AUCTION: "/auctioneer/auction/create",
  PREVIEW_AUCTION_ASSETS: "/auctioneer/auction/preview/:id/assets",
  AUCTION_REPORT: "/auction/report/:id",

  CONTACT_LIST: "/auctioneer/contact-list",
  VIEW_AUCTION: "/auctioneer/auction/:id",
};

export default AUCTIONEER_PATHS;
