import { selectAuthToken } from "@/bidda/store/auth/slice";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { AxiosRequestConfig, AxiosError } from "axios";
import { useSelector } from "react-redux";
import { axiosServer } from "./axios";

// todo: fix eslint warnings
export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method = "GET", data, params, headers }) => {
    try {
      const storage = localStorage?.getItem("authState");

      const { token } = !!storage ? JSON.parse(storage) : "";

      const result = await axiosServer({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      });

      return result;
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (err?.response?.status === 401) {
        localStorage.removeItem("authState");

        const { pathname } = window.location;
        window.location.href = `/auth/login?redirect=${pathname}`;
      }

      if (err?.response?.status === 403) {
        localStorage.removeItem("authState");

        const { pathname } = window.location;
        window.location.href = `/auth/login?redirect=${pathname}`;
      }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
        },
      };
    }
  };
