import { Button, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";

interface Props {
  onUpdateStage: () => void;
  onCancel: () => void;
}
const BankDetails: React.FC<Props> = ({ onUpdateStage, onCancel }) => {
  const [bankName, setBankName] = useState("");
  const [bankIsInNigeria, setBankIsInNigeria] = useState(true);

  return (
    <div className="relative bg-gray-50 h-screen">
      <div className="px-4 pt-5">
        <IoChevronBackOutline
          className="text-xl cursor-pointer"
          onClick={onCancel}
        />
      </div>

      <div className="pb-4 pt-8 px-4">
        <h2 className="font-medium text-2xl">Bank Details</h2>
        <span className="text-gray-700 text-lg">
          Please input your account details
        </span>
      </div>
      <div className="p-4 flex flex-col gap-y-5">
        <div className="flex flex-col">
          <FormLabel className="mb-1">Bank name</FormLabel>
          <Select value={bankName}>
            <MenuItem value={"Nigeria"}>Access</MenuItem>
          </Select>
        </div>

        <div className="flex flex-col">
          <FormLabel className="mb-1">Account number</FormLabel>
          <TextField placeholder="Enter your account number" />
        </div>

        {bankIsInNigeria && (
          <div className="flex flex-col">
            <FormLabel className="mb-1">Bank identifier code</FormLabel>
            <TextField type="number" placeholder="Enter bank identifier code" />
          </div>
        )}

        <p className="text-sm font-medium">
          Information provided is secured and encrypted
        </p>
      </div>
      <div className="fixed bottom-0 px-4 w-full py-3 bg-white shadow-lg">
        <div className="flex gap-x-5">
          <Button variant="outlined" className="w-full" onClick={onUpdateStage}>
            Skip
          </Button>
          <Button
            variant="contained"
            className="w-full"
            onClick={onUpdateStage}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
