import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "@/bidda/api/axiosBaseQuery";
import config from "@/bidda/config/config";

export const bankApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/api/v1/bank` }),
  endpoints: () => ({}),
  reducerPath: "bank",
  keepUnusedDataFor: 60,
  tagTypes: [],
});
