type UseIsAuctioneeReturnType = {
  isAuctionee: boolean;
  auctionee: any;
};

export const useIsAuctionee = (): UseIsAuctioneeReturnType => {
  const storage = localStorage.getItem("authState");
  const { user } = !!storage ? JSON.parse(storage) : false;
  const truthy = !!user.auctionee;
  return { isAuctionee: truthy, auctionee: user.auctionee };
};
