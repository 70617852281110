import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { IAuctionAPIFormat } from "@/bidda/container/types";
import { IAuction } from "@/bidda/types/auction";

type CreateAuctionStages = "auction-info" | "assets" | "invite" | "preview";

interface UtilsDataState {
  auctionCreationData: IAuction | null;
  auctionCreationStage: CreateAuctionStages | null;
}

const initialState: UtilsDataState = {
  auctionCreationData: null,
  auctionCreationStage: null,
};

export const utilSlice = createSlice({
  name: "utilSlice",
  initialState,
  reducers: {
    setAuctionCreationFlow: (state, action: PayloadAction<UtilsDataState>) => {
      state.auctionCreationData = action.payload.auctionCreationData;
      state.auctionCreationStage = action.payload.auctionCreationStage;
    },
    removeAuctionCreationFlow: (state) => {
      state.auctionCreationData = null;
      state.auctionCreationStage = null;
    },
  },
});

export const { removeAuctionCreationFlow, setAuctionCreationFlow } =
  utilSlice.actions;
export default utilSlice.reducer;
