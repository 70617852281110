import { Modal } from "@mui/material";
import React from "react";
import { TailSpin } from "react-loader-spinner";

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
}

const LoadingModal: React.FC<Props> = ({ isModalOpen, onModalClose }) => {
  return (
    <Modal open={isModalOpen} onClose={onModalClose}>
      <div
        className="absolute top-1/2 left-1/2 transform bottom-10 -translate-x-1/2 -translate-y-1/2 
         w-[328px] bg-white shadow-md p-6 rounded-xl grid place-content-center"
      >
        <div className="grid place-content-center pb-5">
          <TailSpin
            height="80"
            width="80"
            color="#003999"
            radius="1"
            visible={true}
          />
        </div>
        <div className="text-lg font-medium text-gray-700 grid place-content-center">
          Signing in
        </div>
      </div>
    </Modal>
  );
};

export default LoadingModal;
