import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface IAuctionState {
  auctions: [] | null;
  auctionCreation?: ICurrentAuctionCreationState;
}

export interface ICurrentAuctionCreationState {
  id: string | null;
  companyId: string | null;
  title: string | null;
  startDate: Date | null;
  endDate: Date | null;
  description: string | null;
  currencyId: number | null;
  auctionFee: number | null;
  timezone: string | null;
  assets: string[];
  contacts: string[];
}

export const auctionCreationState: ICurrentAuctionCreationState = {
  id: null,
  companyId: null,
  title: null,
  startDate: null,
  endDate: null,
  description: "",
  currencyId: null,
  auctionFee: null,
  timezone: null,
  assets: [],
  contacts: [],
};

const initialState: IAuctionState = {
  auctions: null,
  auctionCreation: auctionCreationState,
};

export const slice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    setAuctionCreation: (state, { payload }) => {
      state.auctionCreation = payload as ICurrentAuctionCreationState;
      localStorage.setItem(
        "auctionCreation",
        JSON.stringify(payload)
      );
    },
  },
});

export const { setAuctionCreation } = slice.actions;

export const auctions = slice.reducer;

export const getAuctionCreationState = (state: RootState) =>
  state.auctions.auctionCreation;
