import { useEffect, useState } from 'react';

export const useDidMount = (
    callback: () => void | Promise<void>,
    skip = false
): void => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (isMounted || skip) {
            return;
        }
        void callback();
        setIsMounted(true);
    }, [callback, isMounted, setIsMounted, skip]);
};
