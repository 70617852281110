import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@/bidda/theme/colors";

export const usePasswordStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: colors.primary[500],
    fontWeight: "bold",
    textTransform: "none",
  },
  body: {
    padding: theme.spacing(2),
  },
  header: {
    color: colors.grey[900],
    m: 2,
  },
  phoneField: {
    // height: "60px",
    border: "1px solid #004CCC",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    marginBottom: "20px !important",
    "& .MuiInputBase-root": {
      border: "1px solid #D0D5DD",
      boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
      borderRadius: "8px",
      height: 44,
      padding: "10px",
      "& svg": {
        widht: "20px !important",
      },
      "&::before": {
        borderBottom: "none !important",
      },
      "&::after": {
        borderBottom: "none !important",
      },
    },
  },
}));
